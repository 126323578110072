var c = (r = 0, n = 9) => {
    let t = crypto.getRandomValues(new Uint32Array(1))[0] / 4294967296;
    return Math.floor(t * (n - r)) + r;
  },
  b = r => {
    let n = 0,
      t = 0;
    for (let e = 0, s = r.length; e < s; e++) n = Number.parseInt(r[e], 10), n *= 2 - e % 2, n > 9 && (n -= 9), t += n;
    return Math.ceil(t / 10) * 10 - t;
  },
  I = () => {
    let r = ["19", "19", "19", "20"],
      n = r[c(0, r.length)],
      t = c(0, n === "19" ? 99 : 24);
    t < 10 && (t = "0" + t);
    let e = c(1, 12);
    e < 10 && (e = "0" + e);
    let s = c(1, 28);
    s < 10 && (s = "0" + s), t = t + "", e = e + "", s = s + "";
    let a = "-";
    f(Number.parseInt(n + t), Number.parseInt(e), Number.parseInt(s)) > 100 && (a = "+");
    let o = "";
    for (; o.length < 3;) o += c();
    let i = b(t + e + s + o + "");
    return n + t + e + s + a + o + i;
  },
  D = () => {
    let r = c(1) + "";
    for (; r.length < 9;) r += c(r.length === 2 ? 2 : 0);
    return r += b(r), `${r.substring(0, 6)}-${r.substring(6)}`;
  },
  y = r => {
    let n = Number.parseInt(r[9]),
      t = b(r.substring(0, 9));
    if (t !== n) throw new Error(`Luhn check failed: ${t}`);
  },
  N = (r, n, t) => {
    n -= 1;
    let e = new Date(r, n, t);
    if (e.getFullYear() !== r || e.getMonth() !== n || e.getDate() !== t) throw new Error("Not a valid date");
  },
  f = (r, n, t) => {
    let e = new Date(),
      a = e.getFullYear() - r - 1;
    return (e.getMonth() + 1 > n || e.getMonth() + 1 === n && e.getDate() >= t) && a++, a;
  },
  Y = r => {
    let t = /^(\d{2})?(\d{2})(\d{2})(\d{2})([-|+])?(\d{3})(\d)$/.exec(r.trim());
    if (!t) throw new Error("Not correctly formatted: " + r);
    let e = t[1] || "",
      s = t[2],
      a = t[3],
      u = t[4],
      o = t[5],
      i = t[6],
      l = t[7];
    o === void 0 && (o = "-"), o === "+" && (e = "19");
    let m = Number(a[0]) >= 2,
      h = -1,
      d = "";
    if (!m) {
      if (!e) {
        let p = new Date().getFullYear().toString().substring(2, 4);
        e = Number.parseInt(s) < Number.parseInt(p) ? "20" : "19";
      }
      let g = Number.parseInt(u);
      g > 60 && (g = g - 60), N(Number.parseInt(e + s), Number.parseInt(a), g), h = f(Number.parseInt(e + s), Number.parseInt(a), g), d = a + "/" + g;
    }
    return y(s + a + u + i + l), {
      input: r,
      10: s + a + u + i + l,
      11: s + a + u + o + i + l,
      12: e + s + a + u + i + l,
      13: e + s + a + u + o + i + l,
      age: h,
      birthDay: d,
      isOrg: m
    };
  };
export { I as generate, D as generateOrg, Y as verify };
/**
 * @description
 *
 * This is a little library designed to handle personnummer.
 * The idea is to make it reusable.
 * Pass a string, and it returns a structure with some interesting
 * data or throws an exception if not a valid personnummer.
 *
 * <i>The code for personnummer check is adapted from
 * https://github.com/personnummer/js when copied
 * March 21st 2019 released under MIT licence</i>.
 *
 * @license Apache-2.0
 * @author Daniel Bergdahl <rexsuecia@gmail.com>
 * @copyright 2022 LINAF AB
 *
 */
