import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import {
  ApplicationConfig,
  enableProdMode,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {BrowserModule} from '@angular/platform-browser'
import {provideAnimations} from '@angular/platform-browser/animations'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {environment} from '../environments/environment'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {WINDOW} from './application/data-types'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr')

if (environment.production) {
  enableProdMode()
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule),
    {provide: WINDOW, useValue: window},
    provideAppInitializer(() => {
      return inject(ConfigService).bootstrap()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideZoneChangeDetection({eventCoalescing: true}),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({anchorScrolling: 'enabled'})
    ),
    provideAnimations()
  ]
}