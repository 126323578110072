import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {KalpDataItem, KalpItemType} from '@sparbanken-syd/kalpylator'
import {map, Observable} from 'rxjs'
import {DataStoreItem, DataStoreItemType} from '@sparbanken-syd/datastore'
import {environment} from '../../environments/environment'

/**
 * The format we chose to store data in.
 */
export interface KalpStoreData {
  /**
   * The items, like Loan, Applicant, Income etc.
   */
  kalpItems: KalpDataItem[]

  /**
   * Remember if borgo was possible at the time.
   */
  borgoPossible: boolean

  /**
   * The sub to use to store this with GDPR time.
   * Possible to override
   */
  sub?: string
}

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  /**
   * Stupid conversion function b/c I have messed up this.
   */
  private readonly typeMap: Record<KalpItemType, DataStoreItemType> = {
    applicants: 'APPLICANT',
    loans: 'LOAN',
    children: 'CHILD',
    incomes: 'INCOME',
    properties: 'PROPERTY',
    cars: 'CAR',
    pets: 'PET'
  }

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  public saveKalp(data: KalpStoreData, id?: string): Observable<string> {
    const dataStoreItem: DataStoreItem = {
      data,
      gdprRemove: 0,
      history: [],
      id: '',
      type: 'KALP',
      version: 0
    }
    let saveFunction = this.createItem(dataStoreItem)

    if (id) {
      dataStoreItem.id = id
      saveFunction = this.updateItem(dataStoreItem)
    }
    return saveFunction
      .pipe(
        map((saved: DataStoreItem) => saved.id))
  }

  /**
   * Converts a KALP item to a SAVE item and populates 'sub',
   * returns the received object populated with server data.
   */
  public saveKalpItem(item: KalpDataItem, sub: string | undefined): Observable<DataStoreItem> {
    item.sub = sub
    const dataStoreItem: DataStoreItem = {
      data: item,
      gdprRemove: 0,
      history: [],
      id: '',
      type: this.typeMap[item.type],
      version: 0
    }
    return this.createItem(dataStoreItem)
      .pipe(
        map((saved: DataStoreItem) => {
          // Copy id, gdpr etc. etc. to the original item
          Object.assign(item, saved)
          return saved
        }))
  }

  public createItem(item: DataStoreItem): Observable<DataStoreItem> {
    const url = `${environment.datastoreUrl}/items/${item.type.toLowerCase()}`
    return this.httpClient.put<DataStoreItem>(url, item)
  }

  public updateItem(item: DataStoreItem): Observable<any> {
    const url = `${environment.datastoreUrl}/items/${item.type.toLowerCase()}/${item.id}`
    return this.httpClient.put(url, item)
  }

  public getItem(type: string, id: string): Observable<DataStoreItem> {
    const url = `${environment.datastoreUrl}/items/${type.toLowerCase()}/${id}`
    return this.httpClient.get<DataStoreItem>(url)
  }

  /**
   * Get all items that belong to a user
   */
  public getUser(sub: string): Observable<DataStoreItem[]> {
    const url = `${environment.datastoreUrl}/user`
    const data = {sub}
    return this.httpClient.post<DataStoreItem[]>(url, data).pipe(
      map((items: DataStoreItem[]) => items || [])
    )
  }
}
