import {AsyncPipe, DecimalPipe, NgClass} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs'
import {ActivatedRoute, ParamMap, Router, RouterLink} from '@angular/router'
import {Applicant, KalpDataItem, Kalpylator} from '@sparbanken-syd/kalpylator'
import {distinctUntilChanged, Subject, switchMap, takeUntil} from 'rxjs'
import {filter} from 'rxjs/operators'
import {DataStoreItem} from '@sparbanken-syd/datastore'
import {KALP_ROUTE_PATH, START_ROUTE_PATH} from '../../application/data-types'
import {TestComponent} from '../../common/test/test.component'
import {BorgoService} from '../../services/borgo.service'
import {DatastoreService} from '../../services/datastore.service'
import {InterestService} from '../../services/interest.service'
import {KalpService} from '../../services/kalp.service'
import {KalpDisplayComponent} from '../0-kalp-display/kalp-display.component'
import {SliderComponent} from '../91-slider/slider.component'

@Component({
  selector: 'spb-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  imports: [TestComponent, NgClass, MatTabGroup, MatTab, MatTabContent, KalpDisplayComponent, SliderComponent, RouterLink, MatButton, AsyncPipe, DecimalPipe]
})
export class ResultComponent implements OnInit, OnDestroy {

  public borgo: Kalpylator = new Kalpylator()
  public sparbanken: Kalpylator = new Kalpylator()

  /**
   * The new loan as calculated
   */
  public newLoan = 0

  /**
   * These are values set for debt quota warning.
   * If BORGO && dbq > 6 IF Age < 35, or > 5 if age >= 35
   */
  public debtQuota: number | null = 0

  public maxDebtQuota = 5 // If applicants older than 35

  public age = 0

  /**
   * Back destination to navigate in template
   */
  public backDestination: string[] = []

  /**
   * The base back destination that we spice up with applicants
   */
  private backDestinationBase: string[] = ['/', KALP_ROUTE_PATH]

  /**
   * Cancel all subscriptions
   */
  private endSubscription$ = new Subject()

  constructor(
    public borgoService: BorgoService,
    private dateStoreService: DatastoreService,
    private interestService: InterestService,
    private kalpService: KalpService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  public ngOnInit(): void {
    //Need to reload the latest interest rate if the page reloads.
    this.interestService.getLatestInterest().subscribe()

    this.route.paramMap.pipe(
      filter((params: ParamMap) => !!params.get('id')),
      switchMap((params: ParamMap) => this.dateStoreService.getItem('KALP', params.get('id') as string))
    ).subscribe({
      next: (item: DataStoreItem) => {
        const items = [
          {obs: this.kalpService.applicants$, key: 'applicants'},
          {obs: this.kalpService.incomes$, key: 'incomes'},
          {obs: this.kalpService.children$, key: 'children'},
          {obs: this.kalpService.cars$, key: 'cars'},
          {obs: this.kalpService.properties$, key: 'properties'},
          {obs: this.kalpService.loans$, key: 'loans'}
        ]

        items.forEach((d: any) => {
          d.obs.next(item.data.kalpItems.filter((i: KalpDataItem) => i.type === d.key))
        })
        this.borgoService.borgoPossible$.next(item.data.borgoPossible)
      }
    })

    this.kalpService.changes$.pipe(
      takeUntil(this.endSubscription$),
      distinctUntilChanged()
    ).subscribe({
      next: () => {
        this.borgo = this.kalpService.getKalpylator('borgo')
        this.sparbanken = this.kalpService.getKalpylator('sparbanken')
        this.borgo.calculate()
        this.sparbanken.calculate()
        this.newLoan = this.sparbanken.newLoanTotalAmount

        /**
         * These should be same for both and can be used for setting
         * the warning for LTI when Borgo and out of bounds
         */
        this.debtQuota = this.sparbanken.debtQuota
        this.age = Math.round(this.sparbanken.applicantsAverageAge)
        if (this.age < 36) {
          this.maxDebtQuota = 6
        }
        // Set back to the applicants pnr
        const applicants: string[] = this.sparbanken.applicants.map((a: Applicant) => a.sub as string)
        this.backDestination = this.backDestinationBase.concat(applicants)
      }
    })
  }

  public ngOnDestroy(): void {
    this.endSubscription$.next(null)
  }

  public goToIncome(): void {
    this.kalpService.lastStep = 1
  }

  public reset(): void {
    this.endSubscription$.next(null)
    this.kalpService.reset()
    this.borgoService.reset()
    this.router.navigate([START_ROUTE_PATH]).then()
  }

  public calculate2p(percentage: number): void {
    this.sparbanken.setIncreasedInterest(percentage * 100)
    this.borgo.setIncreasedInterest(percentage * 100)
  }

  public setIncome(income: number): void {
    this.sparbanken.monthlyIncomeOverride = income
    this.borgo.monthlyIncomeOverride = income
  }

  public setTotalLoan(loan: number): void {
    this.sparbanken.newLoanTotalAmountOverride = loan
    this.borgo.newLoanTotalAmountOverride = loan
    this.debtQuota = this.sparbanken.debtQuota
  }
}
